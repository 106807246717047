@import url('https://fonts.googleapis.com/css2?family=Sora&display=swap');

* {
  font-family: 'Sora', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.photo {
  height: 1200px;
  width: 1900px;
}

.shopBox {
  display: flex;  
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2em;
  width: 100%;
  height: 100%;
}
.instructorBox {
  display: flex;  
  flex-direction: row;
  justify-content: center;
  margin:auto;
  margin-bottom: 2em;
  width: 75vw;
  height:auto;
}

.contactBox {
  display: flex;  
  flex-direction: row;
  justify-content: space-around;
  margin:auto;
  margin-bottom: 2em;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 900px){
  .photo{
    width: 80vw;
    height: auto;
  }
}