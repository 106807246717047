@import url(https://fonts.googleapis.com/css2?family=Sora&display=swap);
* {
  font-family: 'Sora', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.photo {
  height: 1200px;
  width: 1900px;
}

.shopBox {
  display: flex;  
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2em;
  width: 100%;
  height: 100%;
}
.instructorBox {
  display: flex;  
  flex-direction: row;
  justify-content: center;
  margin:auto;
  margin-bottom: 2em;
  width: 75vw;
  height:auto;
}

.contactBox {
  display: flex;  
  flex-direction: row;
  justify-content: space-around;
  margin:auto;
  margin-bottom: 2em;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 900px){
  .photo{
    width: 80vw;
    height: auto;
  }
}
.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 50vw;
    margin: auto;
    text-align: center;
  }
  
  .title {
    color: grey;
    font-size: 18px;
  }
  
  button {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 8px;
    color: white;
    background-color: #000;
    text-align: center;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
  }
  
  a {
    text-decoration: none;
    font-size: 22px;
    color: black;
  }
  
  button:hover, a:hover {
    opacity: 0.7;
  }
.navLogo {
    height: 65px;
    width: 290px;
  }



.text {
    display: 'flex';
    justify-content: 'space-evenly';
    height: '90vh';
    width: '100%';
} 
.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
   
  .box {
    position: relative;
    width: 70%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
  }
   
  .close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(15% - 30px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
  }
.instructor{
    display:grid;
    grid-template-columns: repeat(2, 45%);
    grid-gap: 80px;
    list-style: none;
    text-align: center;
    width: 100%;
    justify-content: center;
    margin-bottom: 10%;
    /* padding-left: 5%;
    padding-right: 5%; */
}
.instructor-name{
    font-weight: bold;
    margin-top: 2%;
}
.image-div{
    height: 50vh;
}
.instructor-photo{
    width: 250px;
    height: 45vh;
    object-fit: cover;
    align-items : 'center';
    object-position: 0% 0%;
}

@media screen and (max-width: 900px){
    .instructor{
        display:grid;
        grid-template-columns: repeat(1, 80vw);
        grid-gap: 80px;
        list-style: none;
        text-align: center;
        width: 100%;
        justify-content: center;
        padding-left: 5%;
        padding-right: 5%;
    }
    .instructor-bio{
      width:100%;
    }
    .instructor-photo{
        
        

    }
  }

