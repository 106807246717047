.navLogo {
    height: 65px;
    width: 290px;
  }



.text {
    display: 'flex';
    justify-content: 'space-evenly';
    height: '90vh';
    width: '100%';
} 